.main-header-active{
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

ul{
    list-style: none;
    padding: 0;
    margin-bottom: 0 !important;
}

a{
    text-decoration: none;
}

header{
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 1000;
}

.nav-container{
    display: flex;
    position: relative;
}

.logo-container{
    flex: 1;
    display: flex;
    align-items: center;
}

.header-nav-btn{
    flex: 3;
    display: flex;
}

.header-nav-links{
    flex: 2;
}

.log-sign{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.logo{
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 3rem;
}

.logo span{
    font-weight: 300;
}


.header-nav-links > ul{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header-nav-link{
    position: relative;
}

.header-nav-link > a{
    line-height: 3rem;
    color: rgb(0, 0, 0);
    font-weight: 500;
    padding: 0 .8rem;
    font-size: .95rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .5s;
}

.header-nav-link > a > i{
    margin-left: .2rem;
}

.header-nav-link:hover > a{
    transform: scale(1.1);
}

.header-dropdown{
    position: absolute;
    top: 100%;
    left: 0;
    width: 17rem;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    transition: .5s;
}



.header-dropdown ul{
    position: relative;
}

.header-dropdown-link > a{
    display: flex;
    background-color: rgb(13 32 52 / 89%);
    color: #fff;
    padding: .5rem 1rem;
    font-size: .9rem;
    align-items: center;
    justify-content: space-between;
    transition: .3s;
}

.header-dropdown-link:hover > a{
    background-color: rgb(249, 167, 48);
    color: #fff;
}


.header-dropdown-link i{
    transform: rotate(-90deg);
} 

.arrow{
    position: absolute;
    width: 11px;
    height: 11px;
    top: -5.5px;
    left: 32px;
    background-color: rgb(13 32 52 / 89%);
    transform: rotate(45deg);
    cursor: pointer;
    transition: .3s;
    z-index: -1;
}

.header-dropdown-link:first-child:hover ~ .arrow{
    background-color: rgb(249, 167, 48);
}

.header-dropdown-link{
    position: relative;
}

.header-dropdown.second{
    top: 0;
    left: 100%;
    padding-left: .8rem;
    cursor: pointer;
    transform: translateX(10px);
}

.header-dropdown.second .arrow{
    top: 10px;
    left: -5.5px;
}

.header-nav-link:hover > .header-dropdown,
.header-dropdown-link:hover > .header-dropdown{
    transform: translate(0, 0);
    opacity: 1;
    pointer-events: auto;
}

.hamburger-menu-container{
    flex: 1;
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.hamburger-menu{
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.hamburger-menu div{
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: rgb(0, 0, 0);
    position: relative;
    z-index: 1001;
    transition: .5s;
}

.hamburger-menu div:before,
.hamburger-menu div:after{
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: rgb(0, 0, 0);
    border-radius: 3px;
    transition: .5s;
}

.hamburger-menu div:before{
    transform: translateY(-7px);
}

.hamburger-menu div:after{
    transform: translateY(7px);
}

#check{
    position: absolute;
    top: 50%;
    right: .2rem;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 90000;
    cursor: pointer;
    opacity: 0;
    display: none;
}



#check:checked ~ .hamburger-menu-container .hamburger-menu div{
    background-color: transparent;
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:before{
    transform: translateY(0) rotate(-45deg);
    background-color: rgb(0, 0, 0);
}

#check:checked ~ .hamburger-menu-container .hamburger-menu div:after{
    transform: translateY(0) rotate(45deg);
    background-color: rgb(0, 0, 0);
}

@keyframes animation{
    from{
        opacity: 0;
        transform: translateY(15px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (max-width: 920px){
    .hamburger-menu-container{
        display: flex;
    }

    #check{
        display: block;
    }
    
    .header-nav-btn{
        position: fixed;
        height: calc(100vh - 3rem);
        top: 7.5rem;
        left: 0;
        width: 100%;
        background-color: rgb(0, 0, 0);
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow-x: hidden;
        overflow-y: auto;
        transform: translateX(100%);
        transition: .65s;
    }
    .header-nav-link > a{
        color: #fff;
    }
    #check:checked ~ .header-nav-btn{
        transform: translateX(0);
    }

    #check:checked ~ .header-nav-btn .header-nav-link,
    #check:checked ~ .header-nav-btn .log-sign{
        animation: animation .5s ease forwards;
    }

    .header-nav-links{
        flex: initial;
        width: 100%;
    }

    .header-nav-links > ul{
        flex-direction: column;
    }

    .header-nav-link{
        width: 100%;
        opacity: 0;
        transform: translateY(15px);
    }

    .header-nav-link > a{
        line-height: 1;
        padding: 1.6rem 5rem;
    }

    .header-nav-link:hover > a{
        transform: scale(1);
        background-color: rgb(249, 167, 48);
    }

    .header-dropdown, .header-dropdown.second{
        position: initial;
        top: initial;
        left: initial;
        transform: initial;
        opacity: 1;
        pointer-events: auto;
        width: 100%;
        padding: 0;
        background-color: rgb(249, 167, 48);
        display: none;
    }
    
    .header-nav-link:hover > .header-dropdown,
    .header-dropdown-link:hover > .header-dropdown{
        display: block;
    }

    .header-nav-link:hover > a > i,
    .header-dropdown-link:hover > a > i{
        transform: rotate(360deg);
    }

    .header-dropdown-link > a{
        color: #fff;
        padding: 1.2rem 2rem;
        line-height: 1;
    }

    .header-dropdown.second .header-dropdown-link > a{
        padding: 1.2rem 2rem 1.2rem 3rem;
    }

    .header-dropdown.second .header-dropdown.second .header-dropdown-link > a{
        padding: 1.2rem 2rem 1.2rem 4rem;
    }

    .header-dropdown-link:not(:nth-last-child(2)){
        border-bottom: none;
    }

    .arrow{
        z-index: 1;
        background-color: rgb(249, 167, 48);
        left: 10%;
        transform: scale(1.1) rotate(45deg);
        transition: .5s;
    }

    .header-nav-link:hover .arrow{
        background-color: rgb(249, 167, 48);
    }

    .header-dropdown .header-dropdown .arrow{
        display: none;
    }

    .header-dropdown-link:hover > a{
        background-color: rgb(249, 167, 48);
    }

    .header-dropdown-link:first-child:hover ~ .arrow{
        background-color: rgb(249, 167, 48);
    }

    .header-nav-link > a > i{
        font-size: 1.1rem;
        transform: rotate(-90deg);
        transition: .7s;
    }

    .header-dropdown i{
        font-size: 1rem;
        transition: .7s;
    }

    .log-sign{
        flex: initial;
        width: 100%;
        padding: 1.5rem 1.9rem;
        justify-content: flex-start;
        opacity: 0;
        transform: translateY(15px);
    }
}

.top-header-left > a > .header-links:hover{
    color: #f9a730;
}

@media (max-width: 768px){
    .top-header-left{
        display: none;
    }
    .top-header-right{
        margin: 0 auto;
    }
    .main-header-active > .container > header > .nav-container > .header-nav-btn {
        top: 3.5rem !important;
    }
}
@media (max-width: 452px){
    .top-header-right{
        text-align: center;
    }
    .top-header-right > a:nth-child(3){
        margin-top: 4px;
    }
    .header-nav-btn{
        top: 10rem;
    }
    .main-header-active > .container > header > .nav-container > .header-nav-btn {
        top: 3.5rem !important;
    }
}
@media (max-width: 320px){
    .header-nav-btn{
        top: 9.5rem;
    }
    .main-header-active > .container > header > .nav-container > .header-nav-btn {
        top: 3.5rem !important;
    }
}
