.serv-section-2{
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    padding: 30px;
    height: 100%;
}
.serv-section-2:before {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 0;
    content: " ";
    width: 120px;
    height: 120px;
    background: #f5f5f5;
    border-bottom-left-radius: 136px;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}

.serv-section-2-icon {
    position: absolute;
    top: 18px;
    right: 22px;
    max-width: 60px;
    z-index: 1;
    text-align: center;
}
.serv-section-desc{
    position: relative;
}
.serv-section-2 .text {
    color: #000;
    font-size: 15px;
    margin: 10px 0 0;
    font-weight: 400;
    padding-right: 50px;
}
.section-heading-line-left {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background: #a02b2c;
    border-radius: 25%;
    margin-top: 15px;
    margin-bottom: 5px;
}

.testimonial-box {
    display: flex;
    position: relative;
    padding: 30px 20px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .03);
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .08);
}


.user-img {
    background-image: url('../../../img/profile-icon.webp');
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    min-width: 80px;
    background-size: 100%;
}
.slider-testimonial{
    padding: 20px;
}
.quote {
    position: absolute;
    top: -23px;
    color: #f9a730;
    font-size: 27px;
}