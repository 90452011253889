@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}
ul,
li {
  list-style: none;
}
.btn,
.btn:focus,
.accordion-button:focus {
  border: none;
  box-shadow: none;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none;
  /* border-color: #0d2034; */
  border-color: rgb(249, 167, 48);
}
.form-check-input:focus {
  box-shadow: none;
}

/* get on road price btn css in all vehicles screen*/
.btn-hover:hover {
  background-color: #fff;
}
.nav-link {
  color: #000;
}
.nav-link.active {
  color: #9e2a2b !important;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
}

/* Slider buttons */

.swiper-button-next,
.swiper-button-prev {
  padding: 0 22px;
  background-color: #f9a730;
  border-radius: 20px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: white;
  font-size: 15px !important;
}
